<template>
	<div class="">
		<div v-if="loading" class="d-flex justify-content-center align-items-center">
			<div class="spinner-border" role="status"></div>
		</div>
		<template v-else>
			<div class="container-fluid">
				<button class="btn btn-light mb-4 btn-sm mt-4" @click="goBack()">
					Go back
				</button>

				<div class="row">
					<div class="col-12">
						<div class="card">
							<div class="card-header">
								<div>
									<h4 class="card-header-title">Route Bookings - {{ bookingDate | date('d MMMM, yyyy') }}</h4>
								</div>
								<button class="btn btn-sm btn-outline-primary mr-2" @click="openNotificationModal">
									Notify users
								</button>
								<button class="btn btn-sm btn-primary" @click="downloadReport">
									Download Report
								</button>
							</div>
							<div class="card-body">
								<div class="mb-3" v-if="routeDrivers">
									<div class="col-12 col-lg-6 col-md-8 pl-0">
										<div class="d-flex align-items-center flex-wrap">
											<div class="col pl-0" v-if="itineraries.length && !loadingRouteItineraries">
												<v-select v-model="filter.itineraryId" :options="itineraries" @input="onItineraryChange"
													:reduce="(itinerary) => itinerary.id" label="trip_time" placeholder="Select an itinerary"
													required>
													<template v-slot:option="itinerary">
														<span>{{ itinerary.trip_time }}</span>
													</template>
													<template v-slot:selected-option="itinerary">
														<span>{{ itinerary.trip_time }}</span>
													</template>
												</v-select>
											</div>
											<div class="col">
												<v-select v-model="filter.driver" :options="driversList" label="fname" :filterBy="driverFilter"
													placeholder="Select a driver" required>
													<template v-slot:option="driver">
														<span>{{ driver.fname }} {{ driver.lname }}</span>
													</template>
													<template v-slot:selected-option="driver">
														<span>{{ driver.fname }} {{ driver.lname }}</span>
													</template>
												</v-select>
											</div>
											<button class="btn btn-primary btn-sm ml-1 h-2r" @click="applyFilter()">
												Apply filter
											</button>
										</div>
									</div>
								</div>
								<b-table responsive :items="bookings" :fields="fields" :busy="loadingDetails"
									empty-text="No records available" show-empty>
									<template #table-busy>
										<div class="text-center text-secondary my-2">
											<strong>Loading...</strong>
										</div>
									</template>
									<template #empty="data">
										<p class="text-center">{{ data.emptyText }}</p>
									</template>

									<template #cell(user)="data">
										<div class="d-flex align-items-center">
											<user-avatar :user="data.item.user" size="sm"></user-avatar>
											<div class="ml-2">
												<p class="mb-0">
													{{
														`${data.item.user.fname} ${data.item.user.lname}`
													}}
												</p>
												<span v-if="data.item.user.bus_captain_id">
													<svg-template code="captain" />
													<span class="captain-text ml-1">Bus Captain</span>
												</span>
											</div>
										</div>
									</template>
									<template #cell(created_at)="data">
										<span>{{ formatBookingTime(data.item.created_at) }}</span>
									</template>

									<template #cell(route)="data">
										<route-description :pickup="data.item.pickupRouteBusStop
											? data.item.pickupRouteBusStop.name
											: data.item.pickup.location" :destination="data.item.destinationRouteBusStop
											? data.item.destinationRouteBusStop.name
											: data.item.destination.location">
										</route-description>
									</template>
									<template #cell(pickupCheckin)="data">
										<div class="d-flex flex-column">

											<span class="small font-weight-bold" v-if="data.item.check_in_status">
												<svg-template :code="getCheckedInState(data.item.check_in_status).svg
												">
												</svg-template>
												<span :class="getCICOStatusClass(data.item.check_in_status)">
													{{
														getCheckedInState(data.item.check_in_status).label
													}}</span>
											</span>
										</div>
									</template>
									<template #cell(destinationCheckin)="data">
										<div class="d-flex flex-column">

											<span class="small font-weight-bold" v-if="data.item.check_in_status">
												<svg-template :code="getCheckedInState(data.item.check_in_status).svg
												">
												</svg-template>
												<span :class="getCICOStatusClass(data.item.check_in_status)">
													{{
														getCheckedInState(data.item.check_in_status).label
													}}</span>
											</span>

											<span class="small font-weight-bold" v-if="data.item.check_in_status === 'picked-up' ||
												data.item.check_in_status === 'dropped-off'
											">
												<svg-template :code="getCheckedInState(data.item.check_in_status, true)
													.svg
												">
												</svg-template>
												<span :class="getCICOStatusClass(data.item.check_in_status, true)
												">
													{{
														getCheckedInState(data.item.check_in_status, true)
															.label
													}}
												</span>
											</span>
										</div>
									</template>
									<template #cell(contact)="data">
										<div> <a :href="`mailto:` + data.item.user.email" target="_blank"><span>{{ data.item.user.email }}
										</span></a></div>
										<div> <a :href="`tel:` + data.item.user.phone" target="_blank"><span>{{
											data.item.user.phone
										}}</span></a></div>
									</template>

									<!-- <template #cell(start_date)="data">
                    <span
                      >{{
                        (
                          data.item.start_date ||
                          data.item.userRoute.start_date.split('T')[0]
                        ).split(' ')[0]
                      }}
                    </span>
                  </template> -->
									<template #cell(end_date)="data">
										<span>{{
											(
												data.item.end_date ||
												data.item.userRoute.end_date.split('T')[0]
											).split(' ')[0]
										}}
										</span>
									</template>

									<template #cell(action)="data">
										<div v-if="data.item.status !== 'COMPLETED'" class="dropdown">
											<a class="" href="#" role="button" :id="'dropdownMenuLink_' + data.reference" data-toggle="dropdown"
												aria-haspopup="true" aria-expanded="false">
												<span>
													<svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5C2.82843 3.5 3.5 2.82843 3.5 2C3.5 1.17157 2.82843 0.5 2 0.5Z"
															fill="#444854" />
														<path
															d="M6.5 2C6.5 1.17157 7.17157 0.5 8 0.5C8.82843 0.5 9.5 1.17157 9.5 2C9.5 2.82843 8.82843 3.5 8 3.5C7.17157 3.5 6.5 2.82843 6.5 2Z"
															fill="#444854" />
														<path
															d="M12.5 2C12.5 1.17157 13.1716 0.5 14 0.5C14.8284 0.5 15.5 1.17157 15.5 2C15.5 2.82843 14.8284 3.5 14 3.5C13.1716 3.5 12.5 2.82843 12.5 2Z"
															fill="#444854" />
													</svg>
												</span>
											</a>
											<div class="dropdown-menu" :aria-labelledby="'dropdownMenuLink_' + data.reference">
												<a class="dropdown-item cursor-pointer text-danger" v-if="data.item.user.bus_captain_id"
													@click.prevent="removeAsBusCaptain(data.item.user)">Remove as captain</a>
												<a class="dropdown-item cursor-pointer" v-else @click.prevent="makeBusCaptain(data.item)">Make
													Bus Captain</a>
											</div>
										</div>
									</template>
								</b-table>
							</div>
						</div>
					</div>
				</div>

				<b-modal id="manifest-notification-modal" title="Notify Users (In App Push Notifications)"
					@cancel="closeNotificationModal" @ok="notifyUsers" no-close-on-backdrop no-close-on-esc>
					<div class="col-12">
						<div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="notificationErrorMessage">
							{{ notificationErrorMessage }}
						</div>
						<form>
							<div class="form-group">
								<label for="notification-title">Message Title</label>
								<input v-model="notification.title" required type="text" class="form-control"
									placeholder="Notification title" id="notification-title" />
							</div>
							<div class="form-group">
								<label for="notification-description">Message Description</label>
								<textarea v-model="notification.description" class="form-control form-control-auto" data-autosize=""
									rows="3" placeholder="Start a notification..."
									style="overflow: hidden;overflow-wrap: break-word;height: 69px;" id="notification-description"
									required></textarea>
							</div>
							<p class="font-width-normal mb-0">SMS Notification</p>
							<p class="text-muted font-weight-light mt-0">All users will receive SMS notifications</p>
							<div class="row align-items-center">
								<div class="col-auto">
									<div class="form-group">
										<div class="form-check">
											<input v-model="notification.isSms" class="form-check-input" type="checkbox"
												id="is-sms-notification" />
											<label class="form-check-label" for="is-sms-notification">SMS Notifications</label>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
					<template #modal-footer>
						<div class="w-100">
							<b-button :disabled="sendingNotification || !isValidNotificationForm" variant="primary" class="float-right"
								@click="notifyUsers">
								{{ sendingNotification ? 'Processing...' : 'Notify Users' }}
							</b-button>
							<b-button :disabled="sendingNotification" variant="secondary" class="float-right mr-2"
								@click="closeNotificationModal()">
								Cancel
							</b-button>
						</div>
					</template>
				</b-modal>
			</div>
		</template>
	</div>
</template>

<script>
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'
import UserAvatar from '@/components/core/UserAvatar.vue'
import SvgTemplate from '@/components/core/svg-template'
import busCaptainService from '@/api/bus_captain'
import Swal from 'sweetalert2'
import { extractErrorMessage, filterDriverBy } from '@/utils/helpers'
import RouteDescription from '@/components/modules/routes/RouteDescription'

export default {
  props: ['bookingInfo'],
  components: {
    SvgTemplate,
    UserAvatar,
    RouteDescription
  },
  data() {
    return {
      bookingDate: '',
      companyId: null,
      loading: true,
      bookings: [],
      notification: {
        title: '',
        description: '',
        isSms: false
      },
      busCaptain: null,
      sendingNotification: false,
      notificationErrorMessage: '',
      fields: [
        {
          key: 'user',
          label: 'User'
        },
        {
          key: 'contact',
          label: 'Contact'
        },
        {
          key: 'route',
          label: 'Route'
        },
        {
          key: 'created_at',
          label: 'Booked On'
        },
        {
          key: 'pickupCheckin',
          label: 'Pick Up Status'
        },
        {
          key: 'destinationCheckin',
          label: 'Drop Off Status'
        },
        // {
        //    key: 'start_date',
        //    label: 'Start Date',
        // },
        // {
        //    key: 'end_date',
        //    label: 'End Date',
        // },
        {
          key: 'action',
          label: ''
        }

      ],
      prevRoute: null,
      routeDrivers: [],
      filter: {
        driver: null,
        itineraryId: null
      },
      loadingDetails: false,
      itineraryDrivers: new Map(),
      drivers: [],
      itineraries: [],
      loadingRouteItineraries: false
    }
  },
  computed: {
    isValidNotificationForm() {
      return this.notification?.title && this.notification?.description
    },
    driversList() {
      return this.drivers.length ? this.drivers : this.routeDrivers
    }
  },
  created() {
    const split = this.bookingInfo.split('|')

    this.bookingDate = split[0]
    this.companyId = split[1] || null

    this.init()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.prevRoute = from)
  },
  methods: {
    formatBookingTime(bookingCreatedTime) {
      return moment(bookingCreatedTime).format('lll')
    },
    driverFilter(option, label, search) {
      return filterDriverBy(option, label, search)
    },
    init() {
      this.itineraryId = this.$route.query?.itinerary
      this.fetchRouteItineraries()
      if (this.itineraryId) {
        this.filter.itineraryId = parseInt(this.itineraryId)
      }
      this.loadDriversOnRoute(this.itineraryId)
      this.fetchBookings()
    },
    getCheckedInState(status, isDestination = false) {
      const data = { svg: '', label: '' }
      switch (status) {
        case 'pending':
          data.label = isDestination ? '' : 'Pending'
          data.svg = isDestination ? '' : 'pending'
          break
        case 'picked-up':
          data.label = isDestination ? 'Pending' : 'Checked In'
          data.svg = isDestination ? 'pending' : 'check'
          break
        case 'dropped-off':
          data.label = isDestination ? 'Dropped Off' : 'Checked In'
          data.svg = 'check'
          break
        case 'no-show':
          data.label = isDestination ? '' : 'No show'
          data.svg = isDestination ? '' : 'cancel'
          break
        default:
          return null
      }
      return data
    },
    getItineraryDrivers(itineraryId) {
      return this.itineraryDrivers.get(itineraryId) || this.drivers
    },
    getCICOStatusClass(status, isDestination = false) {
      if (isDestination) {
        switch (status) {
          case 'picked-up':
            return 'status-pending'
          case 'dropped-off':
            return 'status-checked'
        }
      } else {
        switch (status) {
          case 'pending':
            return 'status-pending'
          case 'picked-up':
          case 'dropped-off':
            return 'status-checked'
          case 'no-show':
            return 'status-no-show'
        }
      }
      return ''
    },
    /**
     * @returns {Map}
     * */

    async fetchRouteCaptain() {
      try {
        const params = {
          related: ''
        }
        const captains = await this.axios.get(`/v1/routes/${this.$attrs.routeId}/bus-captains`, { params }).then((res) => res.data.data)
        return captains.reduce((res, item) => {
          const key = `${item.route_itinerary_id}_${item.user_id}`
          res.set(key, item)
          return res
        }, new Map())
      } catch (e) {

        return new Map()
      }
    },
    async fetchBookings(simpleLoader) {
      if (!simpleLoader) {
        this.loading = true
        this.busCaptain = await this.fetchRouteCaptain()
      } else {
        this.loadingDetails = true
      }
      const payload = {
        booking_days: [this.bookingDate]
      }

      if (this.filter.itineraryId) {
        payload.itinerary_id = this.filter.itineraryId
      }
      if (this.filter.driver && this.filter.driver?.id) {
        payload.driver_id = this.filter.driver.id
      }

      this.axios
        .post(`/v1/routes/${this.$attrs.routeId}/bookings/${this.companyId}`, payload)
        .then((res) => {
          const bookings = []
          res.data.data.forEach((dayData) => {
            bookings.push(...(dayData.data || []))
          })
          bookings.forEach((booking) => {
            const key = `${booking.route_itinerary_id}_${booking.user_id}`
            if (this.busCaptain.has(key)) {
              booking.user.bus_captain_id = this.busCaptain.get(key).id
            }
          })
          this.bookings = bookings
        })
        .finally(() => {
          this.loading = false
          this.loadingDetails = false
        })
    },
    loadDriversOnRoute(itinerary) {
      this.fetchingRouteDrivers = true
      if (itinerary) {
        this.fetchItineraryDrivers(itinerary).then((res) => {
          this.drivers = res
        })
      }
      this.axios.get(`/v1/routes/${this.$attrs.routeId}/drivers/`)
        .then(async (res) => {
          this.routeDrivers = (res.data.data || [])
        }).finally(() => (this.fetchingRouteDrivers = false))
    },
    fetchRouteItineraries() {
      this.loadingRouteItineraries = true
      this.axios.get(`/v1/routes/${this.$attrs.routeId}/itineraries?itinerary_only=1`)
        .then((response) => {
          this.itineraries = response.data.data
        }).finally(() => {
          this.loadingRouteItineraries = false
        })
    },
    async onItineraryChange() {
      this.drivers = []
      if (this.filter.itineraryId) {
        this.drivers = await this.fetchItineraryDrivers(this.filter.itineraryId)
      }
    },
    async fetchItineraryDrivers(itineraryId) {
      this.fetchingRouteDrivers = true
      if (this.itineraryDrivers.has(itineraryId)) {
        return this.itineraryDrivers.get(itineraryId)
      } else {
        try {
          const response = await this.axios.get(`/v1/route-itineraries/${itineraryId}/drivers`, {
            params: {
              trip_date: this.bookingDate
            }
          })
          if (response.data) {
            this.itineraryDrivers.set(itineraryId, response.data)
          }
          return response.data
        } catch (error) {
          (extractErrorMessage(error))
        } finally {
          this.fetchingRouteDrivers = false
        }
      }
      return []
    },
    notifyUsers() {
      this.notificationErrorMessage = ''
      if (!this.isValidNotificationForm) {
        this.notificationErrorMessage = 'Please provide a message title and description'
        return
      }
      this.sendingNotification = true
      this.axios
        .post('/v1/notifications/', {
          user_ids: this.bookings.map((booking) => booking.user_id),
          title: this.notification.title,
          body: this.notification.description,
          sms: this.notification.isSms
        })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Notification sent',
            text: 'Notification has been sent to route users successfully',
            showCloseButton: true
          })
          this.closeNotificationModal()

          this.notification = {
            title: '',
            description: '',
            isSms: false
          }
        })
        .catch((e) => {
          this.errorProcessing = true

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
        })
        .finally(() => (this.sendingNotification = false))
    },
    downloadReport() {
      const csvData = this.bookings.map((booking) => {
        return {
          amount: booking.unit_cost,
          pickup: booking.pickup.location,
          destination: booking.destination.location,
          email: booking.user.email,
          name: booking.user.fname + ' ' + booking.user.lname,
          phone: booking.user.phone,
          start_date: moment(booking.start_date).format('YYYY-MM-DD'),
          end_date: moment(booking.end_date).format('YYYY-MM-DD')
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: `Bookings for booking on ${this.bookingDate}`,
        useTextFile: false,
        useBom: true,
        headers: [
          'Amount',
          'Pickup',
          'Destination',
          'Email',
          'Name',
          'Phone',
          'Start Date',
          'End Date'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)

      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    },
    goBack() {
      const fallBack = `/routes/${this.$attrs.routeId}/details`
      this.$router.push({
        path: this.prevRoute ? this.prevRoute.path : fallBack
      })
    },
    closeNotificationModal() {
      this.$bvModal.hide('manifest-notification-modal')
    },
    openNotificationModal() {
      this.$bvModal.show('manifest-notification-modal')
    },
    removeAsBusCaptain(user) {
      busCaptainService.removeAsBusCaptain(user.bus_captain_id, this.$swal, `${user.fname} ${user.lname}`, {
        successFxn: () => {
          this.fetchBookings()
        }
      })
    },
    makeBusCaptain(data) {
      const payload = {
        user_id: data.user.id,
        route_itinerary_id: data.route_itinerary_id
      }
      this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to make this user a bus captain?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return busCaptainService.addAsBusCaptain(payload).catch((error) => {
            const msg =
              error.response && error.response.data
                ? error.response.data.message
                : 'An error occurred, please try again.'
            this.$swal().showValidationMessage(msg)
          })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        this.fetchBookings()
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Action successful!',
            showCloseButton: true
          })
        }
      })
    },
    applyFilter() {
      this.fetchBookings(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.status-checked {
  color: #006633;
}

.status-no-show {
  color: #b01c23;
}

.status-pending {
  color: #ee9714;
}

.captain-text {
  color: #4848ed;
  font-size: 0.75rem;
}

::v-deep .v-select {
  $gray-2: #25292d;

  &.vs--single {
    & .vs__selected {
      max-width: 80%;
    }
  }

  & .vs__search::placeholder {
    color: #d3dce6;
    font-weight: 300;
    font-size: 0.875rem;
  }

  & .vs__dropdown-option {
    padding: 0.5rem 0.5rem;
    font-size: 0.875rem;
    white-space: normal;

    &:hover {
      background: $gray-2;
    }

    &--highlight,
    &--selected {
      background: $gray-2;
      color: #ffffff !important;
    }
  }
}

.h-2r {
  min-height: 2rem;
}
</style>
